export type ILanguages = {
  es: string
  en: string
}

export enum IRoutes {
  CALCULATOR = 'CALCULATOR',
  HOME = 'HOME',
  ABOUT_US = 'ABOUT_US',
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  CONTACT = 'CONTACT',
  GLOSSARY = 'GLOSSARY',
  TUTORIALS = 'TUTORIALS',
  JOBS = 'JOBS',
  FAQ = 'FAQ',
  MATERIALS = 'MATERIALS',
  EXPRESS_PRODUCTION = 'EXPRESS_PRODUCTION',
  DELIVERY_SHIPPING = 'DELIVERY_SHIPPING',
  FINISHING_TOUCHES = 'FINISHING_TOUCHES',
  REFERENCE_CUSTOMERS = 'REFERENCE_CUSTOMERS',
  CUSTOMERS_STORIES = 'CUSTOMERS_STORIES',
  NEWS = 'NEWS',
  PRESS = 'PRESS',
  LABEL_GALLERY = 'LABEL_GALLERY',
  CATEGORIES = 'CATEGORIES',
  PRODUCTS = 'PRODUCTS',
  PROOFING_SERVICE = 'PROOFING_SERVICE',
  ARTWORK_SERVICES = 'ARTWORK_SERVICES',
  PRINT_DATA_DELIVERY = 'PRINT_DATA_DELIVERY',
  SAMPLE_BOOK = 'SAMPLE_BOOK',
  WORLD_OF = 'WORLD_OF',
  ARTWORK_ASSISTANT = 'ARTWORK_ASSISTANT',
  DESIGN_SERVICE = 'DESIGN_SERVICE',
  SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
  TRANSPARENCY_INFO = 'TRANSPARENCY_INFO',
  CART = 'CART',
  LEGAL_WARNING = 'LEGAL_WARNING',
  CONDITIONS_OF_SALE = 'CONDITIONS_OF_SALE',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  PRE_PRINT_ARTWORK = 'PRE_PRINT_ARTWORK',
  UPLOAD_FILES = 'UPLOAD_FILES',
  MY_ACCOUNT = 'MY_ACCOUNT',
  MANAGE_CREDENTIALS = 'MANAGE_CREDENTIALS',
  PROOF_ORDER = 'PROOF_ORDER',
  CUSTOMER_REGISTERED = 'CUSTOMER_REGISTERED',
  MY_ORDERS = 'MY_ORDERS',
  ARTWORK_SERVICES_REVIEW_INFO = 'ARTWORK_SERVICES_REVIEW_INFO',
  ERROR_PAGE = 'ERROR_PAGE',
  SUSTAINABILITY = 'SUSTAINABILITY',
  ORDER_ARTWORK_SERVICE = 'ORDER_ARTWORK_SERVICE',
  SAMPLE_SEARCH = 'SAMPLE_SEARCH',
  PERSONAL_INFOS = 'PERSONAL_INFOS'
}
