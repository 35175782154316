import { protectedApi, api } from '@/client/utils/api'
import {
  GetFileResult,
  GetJobInfo,
  GetPreview,
  SaveUploadFiles,
  SubmitUploadFiles as FinalizeUploadFiles,
  UploadFile
} from './types'

export const getJobInfo: GetJobInfo = async (cID, job) => {
  const url = `/${cID}/upload/jobinfo/${job}`
  const { data } = await protectedApi.get(url)
  return data
}

export const uploadFile: UploadFile = async ({ file, signal, props }) => {
  const urlParams = new URLSearchParams()
  Object.entries(props).forEach((param) => urlParams.set(param[0], String(param[1])))

  urlParams.set('name', String(file.name))
  urlParams.set('size', String(file.size))
  urlParams.set('currentChunkIndex', String(file.index))
  urlParams.set('totalChunks', String(file.length))

  const url = `/dataupload_test/preflight`
  const fullUrl = url + '?' + urlParams.toString()

  const { data } = await api.post(fullUrl, file.base64, { signal })
  return data
}

export const getPreview: GetPreview = async (props) => {
  const urlParams = new URLSearchParams()

  urlParams.set('job', String(props.job))
  urlParams.set('filename', String(props.filename))
  urlParams.set('page', String(props.page))

  const url = `/dataupload_test/preview`
  const fullUrl = url + '?' + urlParams.toString()

  const { data } = await api.get(fullUrl)
  return data
}

export const getFileResult: GetFileResult = async (preflight_hash, page) => {
  const url = `/dataupload/preflight/${preflight_hash}/${page}`
  const { data } = await api.get(url)
  return data
}

export const saveUploadFiles: SaveUploadFiles = async ({ job, files }) => {
  const urlParams = new URLSearchParams()
  urlParams.set('order', job)
  const url = `/dataupload_test/sorts`
  const fullUrl = url + '?' + urlParams.toString()
  const { data } = await api.post(fullUrl, files)
  return data
}

export const finalizeUploadFiles: FinalizeUploadFiles = async (job) => {
  const urlParams = new URLSearchParams()
  urlParams.set('job', job)
  const url = `/dataupload_test/finalize`
  const fullUrl = url + '?' + urlParams.toString()
  const { data } = await api.post(fullUrl)
  return data
}
