import { FC, memo, useEffect, useState, useCallback } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import * as S from './styles'
import { ModalProps } from './types'
import { Icon } from '../Icon'
import { Text } from '../Text'

const isObjectEmpty = (obj: { [key: string]: string }) => Object.keys(obj).length === 0 && obj.constructor === Object

const iconCloseSize: Record<string, IconSizes> = {
  small: 's3',
  medium: 's5',
  large: 's6',
  full: 's7'
}

export const Modal: FC<ModalProps> = memo(
  ({
    size = 'small',
    infos = {},
    close,
    func = () => {},
    buttonName = 'Ok',
    children = null,
    isLoading = false,
    isDisabled = false,
    error = '',
    success = '',
    showActions = true,
    cancelButtonName = 'Cancel',
    className,
    overflow = true
  }) => {
    const [body] = useState(document?.querySelector('body') as HTMLBodyElement)

    const handleKeyUp = useCallback(
      (e) => {
        if (e.keyCode === 27) {
          e.preventDefault()
          close()
          window.removeEventListener('keyup', handleKeyUp, false)
        }
      },
      [close]
    )

    useEffect(() => {
      if (overflow) {
        window.addEventListener('keyup', handleKeyUp, false)
        body.style.overflow = 'hidden'

        return () => {
          window.removeEventListener('keyup', handleKeyUp, false)
          body.style.overflow = 'visible'
        }
      }
    }, [handleKeyUp, body])

    const handleCallbackFunc = useCallback(() => {
      if (!isDisabled) {
        func(close)
      }
    }, [close, isDisabled, func])

    return (
      <S.Background className={className}>
        <S.Content size={size}>
          <S.CloseModal onClick={close} aria-label='Close' data-testid='modal-button-close'>
            <Icon name='close-circle' size={iconCloseSize[size]} color='#00263e' />
          </S.CloseModal>

          {!isObjectEmpty(infos) && (
            <S.Header>
              {infos.title && (
                <Text as='h1' size='lg' weight='bold' align='center' margin='0 0 .5rem'>
                  {infos.title}
                </Text>
              )}

              {infos.subtitle && (
                <Text size='xs' align='center' margin='0 0 1rem'>
                  {infos.subtitle}
                </Text>
              )}
            </S.Header>
          )}

          {children && <S.InnerContent>{children}</S.InnerContent>}

          {error && (
            <Text color='danger' size='xs' weight='bold' align='center'>
              {error}
            </Text>
          )}

          {success && (
            <Text color='success' size='xs' weight='bold' align='center'>
              {success}
            </Text>
          )}

          {showActions && (
            <S.Actions>
              <S.CancelButton onClick={close} data-testid='modal-button-cancel'>
                {cancelButtonName}
              </S.CancelButton>

              <S.ActionButton
                data-testid='modal-button-action'
                isLoading={isLoading}
                isDisabled={isDisabled}
                onClick={isLoading ? undefined : handleCallbackFunc}
              >
                {buttonName}
              </S.ActionButton>
            </S.Actions>
          )}
        </S.Content>
      </S.Background>
    )
  }
)

Modal.displayName = 'Modal'
