import React, { FC, memo, useState } from 'react'
import * as S from './styles'
import { SelectProps } from './types'

export const Select2: FC<SelectProps> = memo(
  ({
    id,
    label = '',
    required = false,
    error = '',
    hasError = false,
    isBlock = false,
    isInline = false,
    disabled = false,
    readOnly = false,
    className = '',
    options,
    placeholder = '',
    propertyValue = 'id',
    propertyLabel = 'name',
    style,
    value,
    onChange,
    ...rest
  }) => {
    const [selectedOption, setSelectedOption] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const handleOptionClick = (option) => {
      setSelectedOption(option)
      setIsOpen(false)

      onChange && onChange({ target: { value: option } } as React.ChangeEvent<HTMLSelectElement>)
    }
    return (
      <S.Container isBlock={isBlock} isInline={isInline} className={className || ''} style={style}>
        {label && <label htmlFor={id}>{label}</label>}
        <S.Group isInline={isInline} hasError={hasError} disabled={disabled} readOnly={readOnly}>
          <div
            className={isOpen ? 'selected-option selected-option-open' : 'selected-option'}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className='selected-ico'>
              <img src='/icons/shipping.svg'></img>
            </span>
            {options.map((option) => {
              if (option.id === value) {
                return <span>{option[propertyLabel]}</span>
              }
              return null
            })}
            <span className='arrow-small-set'>
              <img src='/icons/arrow-down-small.svg'></img>
            </span>
          </div>
          {isOpen && (
            <div className='options'>
              {options.map((option) => (
                <div
                  key={option[propertyValue]}
                  data-value={option[propertyValue]}
                  className={value === option[propertyValue] ? 'each-option each-option-current' : 'each-option'}
                  onClick={() => handleOptionClick(option[propertyValue])}
                >
                  <img src={option.icon} alt={option.label} />
                  <span>{option[propertyLabel]}</span>
                </div>
              ))}
            </div>
          )}
          {/* <select
          id={id}
          required={required}
          aria-required={required}
          aria-disabled={disabled}
          disabled={disabled}
          {...rest}
        >
          {placeholder && <option value=''>{placeholder}</option>}
          {options &&
            options.length > 0 &&
            options.map((option) => (
              <option key={option[propertyValue]} value={option[propertyValue]}>
                <i className='fas fa-coffee'>sd</i> {option[propertyLabel]}
              </option>
            ))}
        </select> */}
        </S.Group>
        {error && <S.Error>{error}</S.Error>}
      </S.Container>
    )
  }
)

Select2.displayName = 'Select2'
