import React, { FC, memo } from 'react'
import * as S from './styles'
import { CheckboxProps } from './types'
import { Text } from '../Text'
import { Icon } from '../Icon'

export const SelectCardCheckBox: FC<CheckboxProps> = memo(
  ({
    kind = 'square',
    type = 'checkbox',
    id,
    name,
    value,
    checked,
    onChange,
    label,
    subText,
    description,
    rightEndText,
    boxType,
    iconName,
    ...rest
  }) => (
    <S.CheckboxContainer htmlFor={id} boxTyle={boxType} checked={checked} {...rest}>
      <S.HiddenInput type={type} id={id} name={name} value={value} checked={checked} onClick={onChange} readOnly />
      <S.Shape kind={kind} checked={checked}>
        <S.InnerShape kind={kind} checked={checked} />
      </S.Shape>

      {/**
       *
       * Applying distinct styles based on the type of label:
       * information labels and regular labels
       *
       */}
      {boxType === 'withIcon' && (
        <S.IconSet className='iconset-inner'>
          {(() => {
            switch (iconName) {
              case 'partial-varnish':
                return <Icon size='s5' name='partial-varnish' />
              case 'artwork-service':
                return <Icon size='s5' name='artwork-service' />
              default:
                return <Icon size='s5' name='artwork-service' />
            }
          })()}
        </S.IconSet>
      )}

      <S.Infos displayType='block'>
        <Text as='span' color='normal' margin='0 0.25rem 0 0' dangerouslySetInnerHTML={{ __html: label }} />
        <Text as='p' color='weak' margin='0 0.25rem 0 0'>
          {subText}
        </Text>
        {description && (
          <Text as='span' color='weak' margin='0'>
            {description}
          </Text>
        )}
      </S.Infos>
      {rightEndText && rightEndText.length !== 0 && (
        <S.RightEnd>
          <Text as='p' color='normal' margin='0 0.25rem 0 0'>
            {rightEndText}
          </Text>
        </S.RightEnd>
      )}
    </S.CheckboxContainer>
  )
)

SelectCardCheckBox.displayName = 'SelectCardCheckBox'
