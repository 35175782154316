import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Amplify } from 'aws-amplify'
import { AppProps } from 'next/app'
import { ComponentsProvider, SiteLoader } from '@/client/components'
import { CartProvider } from '@/client/context/cart'
import { DsProvider } from '@/client/context/ds'
import { ELoaderProvider } from '@/client/context/Loader'
import { QueryProvider } from '@/client/provider/QueryProvider'
import { poppins, jakarta } from '@/infra/configs/fonts'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import '@/infra/styles/icomoon/index.css'

Amplify.configure({
  aws_cognito_region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  ssr: true
})

export function MyApp({ Component, pageProps }: AppProps) {
  const reactQueryDevtools = process.env.NEXT_PUBLIC_REACTQUERYDEVTOOLS === '1'

  return (
    <div>
      <style jsx global>
        {`
          :root {
            --poppins: ${poppins.style.fontFamily};
            --jakarta: ${jakarta.style.fontFamily};
          }
        `}
      </style>
      <QueryProvider pageProps={pageProps}>
        <CartProvider>
          <DsProvider>
            <ELoaderProvider>
              <ComponentsProvider>
                <SiteLoader></SiteLoader>
                <Component {...pageProps} />
                {reactQueryDevtools && <ReactQueryDevtools initialIsOpen />}
              </ComponentsProvider>
            </ELoaderProvider>
          </DsProvider>
        </CartProvider>
      </QueryProvider>
    </div>
  )
}

export default MyApp
